<script>
import Layout from "../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import {tableData} from "./tables/data-advancedtable"
import {getColorByStatus} from "@/helpers/getColorByStatus";
import {formatDate} from "@/helpers/formatDate";

export default {
  page: {
    title: "Analytics",
    meta: [{name: "description", content: appConfig.description}]
  },
  components: {Layout, PageHeader},
  data() {
    return {
      tableData: tableData,
      title: "Analytics",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "name",
      sortDesc: false,
      fields: [
        {key: "name", sortable: true},
        {key: "address", sortable: true},
        {key: "status", sortable: true},
        {key: "date_last_connection", sortable: true},
        {key: "coffee", sortable: true},
        {key: "milk", sortable: true},
        {key: "water", sortable: true},
      ]
    };
  },
  computed: {
    rows() {
      return this.tableData.length;
    }
  },
  methods: {
    formatDate,
    getBgByStatus: getColorByStatus,

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="[]"/>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Data Table</h4>
            <p class="card-title-desc"></p>
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex gap-2 align-items-center">
                    Show
                    <b-form-select class="form-control form-control-sm form-select form-select-sm" v-model="perPage"
                                   size="sm" :options="pageOptions"></b-form-select>
                    entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                  <label class="d-inline-flex align-items-center gap-3">
                    Search:
                    <b-form-input
                        v-model="filter"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0 datatables">
              <b-table
                :fields="fields"
                :items="tableData"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                >
                <template #cell(date_last_connection)="data">
                  {{ formatDate(data.item.date_last_connection)}}
                </template>
                <template #cell(status)="data">
                  <p :class="getBgByStatus(data.item.status)">
                    {{ data.item.status }}
                  </p>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>